<template>
    <div class="report-test">
        <md-card>
            <md-card-header>
                <h4 style="margin:0">Test</h4>
            </md-card-header>
            <md-card-content>
                <md-toolbar class="md-transparent">
                    <div class="md-toolbar-container">
                        <span style="flex:1"></span>
                        <date-range-picker v-model="dateRange"></date-range-picker>
                    </div>
                </md-toolbar>
                <div>{{dateRange}}</div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import DateRangePicker from "@/components/DateRangePicker";
export default {
    components: {
        DateRangePicker
    },
    data() {
        return {
            dateRange: "All" //array | string
        };
    }
};
</script>
